import { call, put, select } from 'redux-saga/effects';
import { ObrasApi } from '../../Data/Obras.api';
import toastHandler from '../../Utils/toastHandler';
import {
  ObraActions, GetObraAction, CreateAction, UpdateArchivedStatusAction,
  GetUsersConstructionSiteAction, BindUsersConstructionSiteAction, UpdateConstructionSiteApiAction,
  UpdateDetailsAction,
  UpdateDisciplinesAction,
  UpdateUsersAction,
  DeleteUsersAction,
  UpdateInvitedUsersAction,
  DeleteInvitedUsersAction,
  UpdatePermissionsAction,
  UpdateNomenclatureAction,
  ListStagesAction,
  CreateStagesAction,
  UpdateStagesAction,
  DeleteStagesAction,
  ImportLibraryAction,
  SettingsStagesAction
} from './Obra.actions';
import { loadError, updateError, createError, listAllTenantOfUserError } from '../../Utils/Messages';
import { history } from '../../Store/index';
import { AuthActions } from 'Store/Auth/Auth.actions';
import { IUserLimitsActive } from 'Store/Auth/interfaces/IAuthState';
import { IGlobalReducerState } from 'Store/Base/interface/IGlobalReducerState';
import { Mixpanel } from 'Utils/MixPanel';
import { IObraListResponse } from 'Data/interfaces/Obra/IObraListResponse';
import { DisciplinaActions } from 'Store/Disciplina/Disciplina.actions';
import { ListagemArquivosActions } from 'Store/ListagemArquivos/ListagemArquivos.actions';
import { ObrasApiV2 } from 'Data/Obras.api.v2';
import { IObraDetailsUpdate, IObraDetailsResponse } from 'Data/interfaces/Obra/IObraDetails';

export function* list() {
  try {
    const { data } = yield call(ObrasApi.list);
    const response: IObraListResponse = { ObrasList: data.data }
    yield put(ObraActions.listSuccess(response));
  } catch {
    yield put(ObraActions.listFailure('Erro ao carregar obras'));
  }
}

export function* getObra({ payload }: GetObraAction) {
  try {
    const { data } = yield call(ObrasApi.getConstructionSite, payload);
    yield put(ObraActions.getObraSuccess(data.data));
  } catch (e: any) {
    const error = e.errors ? e.errors[0].Message : loadError('obras');
    toastHandler.showError(error);
    yield put(ObraActions.getObraFailure(error));
  }
}

export function* createOrUpdate({ payload }: CreateAction) {
  try {
    const {
      firstObraTracking,
      userInfoTracking,
      currentTenantTracking,
    } = payload;
    delete payload.firstObraTracking;
    delete payload.userInfoTracking;
    delete payload.currentTenantTracking;

    const { tenantInvitedUsers, tenantUsers } = yield select((state: IGlobalReducerState) => state.tenant.UsersWithConstruction);
    yield put(ObraActions.addObraChunckData(payload));

    if (payload.shouldSendData) {
      // @ts-ignore:next-line
      const obra = yield select((state: IGlobalReducerState) => state.obra.Obra);
      let userLimitsActive: IUserLimitsActive;

      const filterSharedUsers = tenantUsers?.filter((user: any) => user.User.Id !== userInfoTracking?.Id);
      const isFirstUser = ((tenantInvitedUsers?.length || 0) <= 0) && ((filterSharedUsers?.length || 0) <= 0) && obra?.InvitedUsers?.length > 0;

      if (obra.ConstructionSiteId) {
        console.log({ obra })
        const { data } = yield call(ObrasApi.update, obra);
        yield put(ObraActions.createOrUpdateSuccess(data.data.Value.data));
        yield put(DisciplinaActions.listSuccess(data.data.Value.data));
        yield put(ListagemArquivosActions.addObraChunckData(data.data.Value.data));

        if (isFirstUser) {
          Mixpanel.track({
            name: 'INVITED_FIRST_USER',
            props: { constructionSiteId: data.data.ConstructionSiteId },
            userInfo: userInfoTracking,
            currentListTenant: currentTenantTracking,
          });
        }

        userLimitsActive = {
          QtyActiveUsers: obra?.InvitedUsers?.filter((newUsers: any) => {
            return !tenantInvitedUsers.map((user: any) => user.InvitedUserEmail).includes(newUsers.Email)
          }).length || undefined,
        }
        yield put(AuthActions.toogleUserLimitsActive(userLimitsActive));

        toastHandler.showSuccess('Obra Atualizada com sucesso!');

      } else {
        const { data } = yield call(ObrasApi.create, obra);
        yield put(ObraActions.list());

        if (firstObraTracking) {
          Mixpanel.track({
            name: 'CREATE_FIRST_OBRA',
            props: { constructionSiteId: data.data.ConstructionSiteId },
            userInfo: userInfoTracking,
            currentListTenant: currentTenantTracking,
          });
        }
        if (isFirstUser) {
          Mixpanel.track({
            name: 'INVITED_FIRST_USER',
            props: { constructionSiteId: data.data.ConstructionSiteId },
            userInfo: userInfoTracking,
            currentListTenant: currentTenantTracking,
          });
        }

        userLimitsActive = {
          QtyActiveConstructionSites: 1,
          QtyActiveUsers: obra?.InvitedUsers?.filter((newUsers: any) => {
            return !tenantInvitedUsers.map((user: any) => user.InvitedUserEmail).includes(newUsers.Email)
          }).length || undefined,
        }
        yield put(AuthActions.toogleUserLimitsActive(userLimitsActive));

        if (obra.ExistingConstructionSiteTokenId) {
          history.push(`/obras/detail/${data.data.ConstructionSiteId}/true`);
        } else {
          window.location.href = data.info.redirectUrl;
        }
      }
    }
  } catch (e: any) {
    const error = e.errors ? e.errors[0].Message : createError('obra');
    toastHandler.showError(error);
    yield put(ObraActions.createOrUpdateFailure(error));
  }
}

export function* updateArchivedStatus({ payload }: UpdateArchivedStatusAction) {
  try {
    yield call(ObrasApi.updateArchivedStatus, payload);
    yield put(ObraActions.updateArchivedStatusSuccess(payload));

    let userLimitsActive: IUserLimitsActive;
    if (payload.isArchived) {
      userLimitsActive = {
        QtyActiveConstructionSites: -1,
      }
    } else {
      userLimitsActive = {
        QtyActiveConstructionSites: 1,
      }
    }
    yield put(AuthActions.toogleUserLimitsActive(userLimitsActive));

    history.push(`/obras`);
    toastHandler.showSuccess('Obra arquivada/desarquivada com sucesso');
  } catch (e: any) {
    const error = e.errors ? e.errors[0].Message : 'Erro ao arquivar/desarquivar obra';
    toastHandler.showError(error);
    yield put(ObraActions.updateArchivedStatusFailure(error));
  }
}

export function* getDisciplines() {
  try {
    const { data } = yield call(ObrasApi.getDisciplines);
    yield put(ObraActions.getDisciplinesSuccess(data.data));
  } catch (e: any) {
    const error = e.errors ? e.errors[0].Message : loadError('disciplinas');
    toastHandler.showError(error);
    yield put(ObraActions.getDisciplinesFailure(error));
  }
}

export function* getUsersConstructionSite({ payload }: GetUsersConstructionSiteAction) {
  try {
    const { data } = yield call(ObrasApi.getUserByConstructionSite, payload);
    const response = data.data;
    response.ConstructionSiteId = payload;
    yield put(ObraActions.getUsersConstructionSiteSuccess(response));
  } catch (e: any) {
    const error = e.errors ? e.errors[0].Message : loadError('usuários da Obra');
    toastHandler.showError(error);
    yield put(ObraActions.getUsersConstructionSiteFailure(error));
  }
}

export function* bindUsersConstructionSite({ payload }: BindUsersConstructionSiteAction) {
  try {
    const {
      firstUserTracking,
      userInfoTracking,
      currentTenantTracking,
    } = payload;
    delete payload.firstUserTracking;
    delete payload.userInfoTracking;
    delete payload.currentTenantTracking;

    yield call(ObrasApi.bindUsersToConstructionSite, payload);
    yield put(ObraActions.bindUsersConstructionSiteSuccess(payload));

    const userLimitsActive: IUserLimitsActive = {
      QtyActiveUsers: payload.newInvitedUsers,
    }

    if (firstUserTracking) {
      Mixpanel.track({
        name: 'INVITED_FIRST_USER',
        props: { constructionSiteId: payload.ConstructionSiteId },
        userInfo: userInfoTracking,
        currentListTenant: currentTenantTracking,
      });
    }
    yield put(AuthActions.toogleUserLimitsActive(userLimitsActive));

    toastHandler.showSuccess('Usuários atualizados com sucesso.');
  } catch (e: any) {
    const error = e.errors ? e.errors[0].Message : updateError('usuários da Obra');
    toastHandler.showError(error);
    yield put(ObraActions.bindUsersConstructionSiteFailure(error));
  }
}

export function* listConstructionSiteAccounts() {
  try {
    const { data } = yield call(ObrasApi.listConstructionSiteAccountsInfo);
    yield put(ObraActions.listConstructionSiteAccountsSuccess(data.data));
  } catch (e: any) {
    const error = e.errors ? e.errors[0].Message : loadError('contas da construtora');
    toastHandler.showError(error);
    yield put(ObraActions.listConstructionSiteAccountsFailure(error));
  }
}

export function* updateConstructionSiteApi({ payload }: UpdateConstructionSiteApiAction) {
  try {
    const { data } = yield call(ObrasApi.updateConstructionSiteApi, payload);
    yield put(ObraActions.list());

    if (payload.isRedirect) {
      if (payload.ExistingConstructionSiteTokenId) {
        history.push(`/obras/detail/${payload.CsId}`);
      } else {
        window.location.href = data.data.redirectUrl;
      }
      yield put(ObraActions.updateArchivedStatusSuccess(data.data));
    }
    else {
      yield put(ObraActions.listConstructionSitesStorage());
      toastHandler.showSuccess("Obra atualizada com sucesso");
    }
  } catch (e: any) {
    const error = e.errors ? e.errors[0].Message : updateError('serviço de nuvem');
    toastHandler.showError(error);
    yield put(ObraActions.updateArchivedStatusFailure(error));
  }
}

export function* listConstructionSitesStorage() {
  try {
    const { data } = yield call(ObrasApi.listConstructionSitesStorage);
    yield put(ObraActions.listConstructionSitesStorageSuccess(data.data));
  } catch (e: any) {
    const error = e.errors ? e.errors[0].Message : loadError('contas da construtora');
    toastHandler.showError(error);
    yield put(ObraActions.listConstructionSitesStorageFailure(error));
  }
}

export function* listAllConstructionsSitesOfUser() {
  try {
    const { data } = yield call(ObrasApi.fetchAllConstructionsOfUser);
    yield put(ObraActions.listAllConstructionsSitesOfUserSuccess(data.data));
  } catch (err: any) {
    const error = err.errors ? err.errors[0].Message : listAllTenantOfUserError;
    toastHandler.showError(error);
    yield put(ObraActions.listAllConstructionsSitesOfUserFailure(error));
  }
}

export function* updateDetails({ payload }: UpdateDetailsAction) {
  try {
    const request: IObraDetailsUpdate = {
      name: payload.Name,
      type: payload.Type,
      address: payload.Address,
      avatarFileName: payload.AvatarFileName,
    };
    const {data} = yield call(ObrasApiV2.updateDetails, payload.constructionStieId, request);
    console.log('updateDetails', data.data);

    const response: IObraDetailsResponse = {
      constructionStieId: payload.constructionStieId,
      name: payload.Name,
      type: payload.Type,
      address: payload.Address,
      avatarFileName: payload.AvatarFileName,
    };
    yield put(ObraActions.updateDetailsSuccess(response));
    toastHandler.showSuccess('Dados da Obra atualizados com sucesso!');
  } catch (e: any) {
    const error = e.errors ? e.errors[0].Message : 'Erro ao atualizar dados da Obra!';
    toastHandler.showError(error);
    yield put(ObraActions.updateDetailsFailure(error));
  }
}

export function* updateDisciplines({ payload }: UpdateDisciplinesAction) {
  try {
    const { data } = yield call(ObrasApiV2.updateDisciplines, payload);

    yield put(ObraActions.updateDisciplinesSuccess({
      constructionStieId: payload.constructionStieId,
      disciplines: data.data,
    }));
    toastHandler.showSuccess('Disciplinas da Obra atualizadas com sucesso!');
  } catch (e: any) {
    const error = e.errors ? e.errors[0].Message : 'Erro ao atualizar Disciplinas!';
    toastHandler.showError(error);
    yield put(ObraActions.updateDisciplinesFailure(error));
  }
}

export function* updateUsers({ payload }: UpdateUsersAction) {
  try {
    const { data } = yield call(ObrasApiV2.updateUsers, payload);

    yield put(ObraActions.updateUsersSuccess({
      constructionStieId: payload.constructionStieId,
      user: data.data,
    }));
    toastHandler.showSuccess('Obra compartilhada com sucesso!');
  } catch (e: any) {
    const error = e.errors ? e.errors[0].Message : 'Erro ao compartilhar Obra!';
    toastHandler.showError(error);
    yield put(ObraActions.updateUsersFailure(error));
  }
}

export function* deleteUsers({ payload }: DeleteUsersAction) {
  try {
    yield call(ObrasApiV2.deleteUsers, payload);

    yield put(ObraActions.deleteUsersSuccess({
      constructionStieId: payload.constructionStieId,
      userId: payload.userId,
    }));
    toastHandler.showSuccess('Compartilhamento da Obra removido com sucesso!');
  } catch (e: any) {
    const error = e.errors ? e.errors[0].Message : 'Erro ao remover compartilhamento da Obra!';
    toastHandler.showError(error);
    yield put(ObraActions.deleteUsersFailure(error));
  }
}

export function* updateInvitedUsers({ payload }: UpdateInvitedUsersAction) {
  try {
    const { data } = yield call(ObrasApiV2.updateInvitedUsers, payload);

    yield put(ObraActions.updateInvitedUsersSuccess({
      constructionStieId: payload.constructionStieId,
      user: data.data,
    }));
    toastHandler.showSuccess('Convite enviado com sucesso!');
  } catch (e: any) {
    const error = e.errors ? e.errors[0].Message : 'Erro ao convidar usuário!';
    toastHandler.showError(error);
    yield put(ObraActions.updateInvitedUsersFailure(error));
  }
}

export function* deleteInvitedUsers({ payload }: DeleteInvitedUsersAction) {
  try {
    yield call(ObrasApiV2.deleteInvitedUsers, payload);

    yield put(ObraActions.deleteInvitedUsersSuccess({
      constructionStieId: payload.constructionStieId,
      invitedEmail: payload.invitedEmail,
    }));
    toastHandler.showSuccess('Convite removido com sucesso!');
  } catch (e: any) {
    const error = e.errors ? e.errors[0].Message : 'Erro ao remover convite!';
    toastHandler.showError(error);
    yield put(ObraActions.deleteInvitedUsersFailure(error));
  }
}

export function* updatePermissionsUsers({ payload }: UpdatePermissionsAction) {
  try {
    yield call(ObrasApiV2.updatePermissions, payload);

    yield put(ObraActions.updatePermissionsUsersSuccess({
      constructionStieId: payload.constructionStieId,
      permissions: payload.permissions,
    }));
    toastHandler.showSuccess('Permissões da Obra atualizadas com sucesso!');
  } catch (e: any) {
    const error = e.errors ? e.errors[0].Message : 'Erro ao atualizar Permissões!';
    toastHandler.showError(error);
    yield put(ObraActions.updatePermissionsUsersFailure(error));
  }
}

export function* updateNomenclatures({ payload }: UpdateNomenclatureAction) {
  try {
    const { data } = yield call(ObrasApiV2.updateNomenclatures, payload);

    yield put(ObraActions.updateNomenclatureSuccess({
      constructionStieId: payload.constructionStieId,
      nomenclatures: data.data,
    }));
    toastHandler.showSuccess('Nomenclatura da Obra atualizada com sucesso!');
  } catch (e: any) {
    const error = e.errors ? e.errors[0].Message : 'Erro ao atualizar Nomenclatura!';
    toastHandler.showError(error);
    yield put(ObraActions.updateNomenclatureFailure(error));
  }
}

export function* listStages({ payload }: ListStagesAction) {
  try {
    const { data } = yield call(ObrasApiV2.listStages, payload);
    console.log('listStages', data.data);

    yield put(ObraActions.listStagesSuccess({
      constructionStieId: payload,
      stages: data.data,
    }));
  } catch (e: any) {
    const error = e.errors ? e.errors[0].Message : 'Erro ao listar Etapas!';
    toastHandler.showError(error);
    yield put(ObraActions.listStagesFailure(error));
  }
}

export function* createStages({ payload }: CreateStagesAction) {
  try {
    const { data } = yield call(ObrasApiV2.createStages, payload);

    yield put(ObraActions.createStagesSuccess({
      constructionStieId: payload.constructionStieId,
      stage: data.data,
    }));
    toastHandler.showSuccess('Etapa criada com sucesso!');
  } catch (e: any) {
    const error = e.errors ? e.errors[0].Message : 'Erro ao criar Etapa!';
    toastHandler.showError(error);
    yield put(ObraActions.createStagesFailure(error));
  }
}

export function* updateStages({ payload }: UpdateStagesAction) {
  try {
    const { data } = yield call(ObrasApiV2.updateStages, payload);
    console.log('updateStages', data.data);

    yield put(ObraActions.updateStagesSuccess({
      constructionStieId: payload.constructionStieId,
      stage: data.data,
    }));
    toastHandler.showSuccess('Etapa atualizada com sucesso!');
  } catch (e: any) {
    const error = e.errors ? e.errors[0].Message : 'Erro ao atualizar Etapa!';
    toastHandler.showError(error);
    yield put(ObraActions.updateStagesFailure(error));
  }
}

export function* deleteStages({ payload }: DeleteStagesAction) {
  try {
    yield call(ObrasApiV2.deleteStages, payload);

    yield put(ObraActions.deleteStagesSuccess({
      constructionStieId: payload.constructionStieId,
      stageId: payload.stageId,
    }));
    toastHandler.showSuccess('Etapa excluída com sucesso!');
  } catch (e: any) {
    const error = e.errors ? e.errors[0].Message : 'Erro ao excluir Etapa!';
    toastHandler.showError(error);
    yield put(ObraActions.deleteStagesFailure(error));
  }
}

export function* listLibrary() {
  try {
    const { data } = yield call(ObrasApiV2.listLibraries);

    yield put(ObraActions.listLibrarySuccess(data.data));
  } catch (e: any) {
    const error = e.errors ? e.errors[0].Message : 'Erro ao listar Bibliotecas!';
    toastHandler.showError(error);
    yield put(ObraActions.listLibraryFailure(error));
  }
}

export function* importLibrary({ payload }: ImportLibraryAction) {
  try {
    const { data } = yield call(ObrasApiV2.importLibrary, payload);
    console.log('importLibrary', data.data);

    yield put(ObraActions.importLibrarySuccess(payload));
    toastHandler.showSuccess('Biblioteca importada com sucesso!');
  } catch (e: any) {
    const error = e.errors ? e.errors[0].Message : 'Erro ao importar Biblioteca!';
    toastHandler.showError(error);
    yield put(ObraActions.importLibraryFailure(error));
  }
}

export function* settingsStages({ payload }: SettingsStagesAction) {
  try {
    yield call(ObrasApiV2.settingsStages, payload);

    yield put(ObraActions.settingsStagesSuccess(payload));
    toastHandler.showSuccess('Configuração alterada com sucesso!');
  } catch (e: any) {
    const error = e.errors ? e.errors[0].Message : 'Erro ao editar configuração da Etapa!';
    toastHandler.showError(error);
    yield put(ObraActions.settingsStagesFailure(error));
  }
}
