import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { ObraActions } from "Store/Obra/Obra.actions";
import { UniqueIdentifier } from "@dnd-kit/core";
import { headerStagesTable, InputStageRequest, SettingsConfigEnum, IStages } from "./stages.model";
import Button from "Components/UI/Button";
import Item from "./components/Item";
import { Icon } from "Components/UI";
import CreateOrEdit from "./components/CreateOrEdit";
import SimpleDnDListContext from "Components/UI/SimpleDnDListContext";
import SimpleSortableItemWithButton from "Components/UI/SimpleSortableItemWithButton";
import DeleteModal from "./components/Delete";
import Library from "./components/Library";
import EmptyList from "./components/EmptyList";
import PhaseNomenclatura from "./components/PhaseNomenclatura";
import IntegrationDiscipline from "./components/IntegrationDiscipline";
import DisciplineActivities from "./components/DisciplineActivities";
import {
  ICreateStageRequest,
  IDeleteStageRequest,
  ISettingsStageRequest,
  IStage,
  IUpdateStageRequest,
} from "Data/interfaces/Obra/IObraStages";
import { IConstructionSiteStageLibraryRequest } from "Data/interfaces/Obra/IObraLibraries";
import LoadingStages from "Components/UI/CustomLoading/LoadingStages";
import SearchInput from "Components/UI/SearchInput";
import EmptySearch from "./components/EmptySearch";
import {
  BtnContainer,
  Container,
  Content,
  HeaderContent,
  Main,
  SearchContainer,
  SwitchContainer,
  SwitchStyled,
  Title,
  Warning,
} from "./styles";

const Stages: FC<IStages> = ({
  csId,
  stages,
  libraries,
  disciplines,
  loading,
  listStagesLoading,
  listLibrariesLoading,
  obras,
  ValidateStagesInNomenclature,
  AllowOnlyStagesLinkedToDisciplinesInUpload,
  AllowOnlyStagesLinkedToDisciplinesInTopics,
}) => {
  const dispatch = useDispatch();

  const [showEdit, setShowEdit] = useState(false);
  const [sentRequest, setSentRequest] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showLibrary, setShowLibrary] = useState(false);
  const [search, setSearch] = useState('');
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [stageForEdit, setStageForEdit] = useState<IStage | null>(null);
  const [phasesData, setPhasesData] = useState<IStage[]>([]);
  const [showPhaseNomenclaturaModal, setShowPhaseNomenclaturaModal] = useState(false);
  const [showIntegrationDisciplinesModal, setShowIntegrationDisciplinesModal] = useState(false);
  const [showDisciplineActivitiesModal, setShowDisciplineActivitiesModal] = useState(false);

  const activeData = useMemo(() => {
    if (activeId && phasesData?.length > 0) {
      const active = phasesData.find(phase => phase.TopicStageId === Number(activeId));
      if (active) {
        return active;
      }
    }
    return null;
  }, [activeId, phasesData]);

  const filteredPhasesData = useMemo(() => {
    if (search && phasesData?.length > 0) {
      return phasesData.filter(phase => {
        const lowerCaseSearch = search.toLowerCase();
        return phase.Acronym?.toLowerCase().includes(lowerCaseSearch) ||
          phase.Name?.toLowerCase().includes(lowerCaseSearch) ||
          phase.TopicStageId?.toString()?.toLowerCase().includes(lowerCaseSearch);
      });
    }

    return phasesData;
  }, [phasesData, search]);

  const handleSubmit = (values: InputStageRequest) => {
    if (!showEdit || !values) return;

    if (stageForEdit) {
      const request: IUpdateStageRequest = {
        constructionStieId: csId,
        stageId: stageForEdit.TopicStageId,
        stage: {
          constructionSiteDisciplineIds: values.disciplineIds,
          isDefault: values.isDefault,
        },
      };
      if (values.stageName) request.stage.name = values.stageName;
      if (values.acronym) request.stage.acronym = values.acronym;
      dispatch(ObraActions.updateStages(request));
    } else {
      const request: ICreateStageRequest = {
        constructionStieId: csId,
        stage: {
          name: values.stageName,
          acronym: values.acronym,
          isDefault: values.isDefault,
          constructionSiteDisciplineIds: values.disciplineIds,
        },
      };
      dispatch(ObraActions.createStages(request));
    }
    setSentRequest(true);
  }

  const handleDelete = (stageId: number) => {
    if (!stageId) return;

    const request: IDeleteStageRequest = {
      constructionStieId: csId,
      stageId,
    };
    dispatch(ObraActions.deleteStages(request));
    setSentRequest(true);
  }

  const handleImportSubmit = (values: IConstructionSiteStageLibraryRequest) => {
    if (!showLibrary || !values) return;

    dispatch(ObraActions.importLibrary(values));
    setSentRequest(true);
  }

  const toogleSettingsConfig = (toggle: SettingsConfigEnum) => {
    const request: ISettingsStageRequest = {
      constructionStieId: csId,
      config: {
        validateStagesInNomenclature: ValidateStagesInNomenclature,
        allowOnlyStagesLinkedToDisciplinesInUpload: AllowOnlyStagesLinkedToDisciplinesInUpload,
        allowOnlyStagesLinkedToDisciplinesInTopics: AllowOnlyStagesLinkedToDisciplinesInTopics,
      }
    }

    if (toggle === SettingsConfigEnum.validateStagesInNomenclature) {
      request.config.validateStagesInNomenclature = !ValidateStagesInNomenclature;
      if (!request.config.validateStagesInNomenclature) {
        request.config.allowOnlyStagesLinkedToDisciplinesInUpload = false;
      }
    }
    if (toggle === SettingsConfigEnum.allowOnlyStagesLinkedToDisciplinesInUpload) {
      request.config.allowOnlyStagesLinkedToDisciplinesInUpload = !AllowOnlyStagesLinkedToDisciplinesInUpload;
    }
    if (toggle === SettingsConfigEnum.allowOnlyStagesLinkedToDisciplinesInTopics) {
      request.config.allowOnlyStagesLinkedToDisciplinesInTopics = !AllowOnlyStagesLinkedToDisciplinesInTopics;
    }

    dispatch(ObraActions.settingsStages(request));
  }

  const openDeleteModal = (phase: IStage) => {
    setStageForEdit(phase);
    setShowDelete(true);
  }

  const openEditModal = (phase: IStage) => {
    setStageForEdit(phase);
    setShowEdit(true);
  }

  const openLibrary = () => {
    dispatch(ObraActions.listLibrary());
    setShowLibrary(true);
  }

  const closeAllModal = () => {
    setSentRequest(false);
    setStageForEdit(null);
    setShowEdit(false);
    setShowPhaseNomenclaturaModal(false);
    setShowIntegrationDisciplinesModal(false);
    setShowDisciplineActivitiesModal(false);
    setShowDelete(false);
    setShowLibrary(false);
  }

  const handleSetNewData = useCallback((
    start: IStage,
    end: IStage,
    data: IStage[],
  ) => {
    if (start.TopicStageId && end.TopicStageId) {
      setPhasesData(data);
    }
  }, []);

  useEffect(() => {
    dispatch(ObraActions.listStages(csId));
  }, []);

  useEffect(() => {
    if (stages) {
      const orderedStages = stages.sort((a, b) => a.Order - b.Order);
      setPhasesData(orderedStages);
    }
  }, [stages]);

  useEffect(() => {
    if (sentRequest && !loading) {
      closeAllModal();
    }
  }, [sentRequest, loading]);

  return (
    <>
      <Container>
        <Title>Etapas</Title>
        <Main>
          <Content>
            {(listStagesLoading || phasesData?.length > 0) && (
              <>
                <SearchContainer>
                  <div className="searchBox">
                    <SearchInput
                      setSearch={setSearch}
                      inputPlaceholder="Pesquise por uma etapa..."
                    />
                  </div>
                </SearchContainer>
                <HeaderContent>
                  {headerStagesTable.map(head => (
                    <div key={`h-p-t-${head.id}`} className={`column${head.id}`}>
                      <span className="columnText">{head.label}</span>
                    </div>
                  ))}
                </HeaderContent>
                {listStagesLoading && (
                  <LoadingStages multiple={6} />
                )}
                {!listStagesLoading && filteredPhasesData?.length > 0 && (
                  <SimpleDnDListContext
                    data={filteredPhasesData.map(phase => ({ ...phase, id: phase.TopicStageId.toString() }))}
                    dragOverlay={activeId && activeData ? (
                      <SimpleSortableItemWithButton
                        key={activeData.TopicStageId}
                        id={activeData.TopicStageId.toString()}
                        activeId={activeId}
                        dragClassName="customDragRow"
                        btnClassName="customRowBtn"
                      >
                        <Item
                          key={`d-p-f-${activeData.TopicStageId}`}
                          stage={activeData}
                          search={search}
                          onUpdate={() => undefined}
                          onRemove={() => undefined}
                        />
                      </SimpleSortableItemWithButton>
                    ) : null}
                    onActiveId={setActiveId}
                    onSetNewData={handleSetNewData}
                  >
                    {filteredPhasesData.map((phase) => (
                      <SimpleSortableItemWithButton
                        key={phase.TopicStageId.toString()}
                        id={phase.TopicStageId.toString()}
                        className="customRow"
                        btnClassName="customRowBtn"
                        btnShowInHover
                        btnShowIsAbsolute
                      >
                        <Item
                          key={`d-p-f-${phase.TopicStageId}`}
                          stage={phase}
                          search={search}
                          onUpdate={openEditModal}
                          onRemove={openDeleteModal}
                        />
                      </SimpleSortableItemWithButton>
                    ))}
                  </SimpleDnDListContext>
                )}
                {!listStagesLoading && search &&
                  filteredPhasesData?.length === 0 && (
                    <EmptySearch searchText={search} />
                  )}
              </>
            )}
            {!listStagesLoading && phasesData?.length === 0 && (
              <EmptyList
                onEdit={() => setShowEdit(true)}
                onLib={openLibrary}
              />
            )}
          </Content>
        </Main>

        {(listStagesLoading || phasesData?.length > 0) && (
          <BtnContainer>
            <Button
              type="text"
              className="addBtn"
              disabled={loading || listStagesLoading}
              onClick={() => setShowEdit(true)}
            >
              <Icon
                icon="adicionar"
                customSize={14}
                className="actionIcon"
              />
              <span className="textBtn">Criar nova etapa</span>
            </Button>
            {!listStagesLoading && (
              <Button
                type="text"
                className="libBtn"
                onClick={openLibrary}
              >
                <Icon
                  icon="listDetailVision"
                  customSize={18}
                  className="actionIcon"
                />
                <span className="textBtn">Biblioteca de Etapas</span>
              </Button>
            )}
          </BtnContainer>
        )}
      </Container>

      <Container>
        <Title>Nomenclatura</Title>
        <Main>
          {(!phasesData || phasesData?.length === 0) && (
            <Warning>
              A nomenclatura não conta com o campo de Etapa e por isso, não é possível ativar a validação de etapas na nomenclatura.
            </Warning>
          )}

          <SwitchContainer>
            <SwitchStyled
              checked={ValidateStagesInNomenclature}
              onChange={() => setShowPhaseNomenclaturaModal(true)}
            />
            <div className="textContent">
              <span className="title">
                Validar etapas na nomenclatura
              </span>
              <span className="text">
                Ao enviar um arquivo que tenha a extensão cadastrada na validação de nomenclatura, o campo de Etapa será validado com base nas etapas cadastradas. Não será possível escolher uma etapa que não esteja cadastrada.
              </span>
            </div>
          </SwitchContainer>
          <SwitchContainer>
            <SwitchStyled
              checked={AllowOnlyStagesLinkedToDisciplinesInUpload}
              onChange={() => ValidateStagesInNomenclature ? setShowIntegrationDisciplinesModal(true) : undefined}
              disabled={!ValidateStagesInNomenclature}
            />
            <div className="textContent">
              <span className="title">
                Permitir apenas as etapas vinculadas à disciplina no upload
              </span>
              <span className="text">
                Só será permitido selecionar as etapas que estão vinculadas com a disciplina que está sendo feito o upload.
              </span>
            </div>
          </SwitchContainer>
        </Main>

        <BtnContainer margintop={24}>
          <Button
            type="text"
            className="configBtn"
            onClick={() => undefined}
          >
            <span className="textConfigBtn">Configurar Nomenclatura</span>
            <Icon
              icon="openNewTab"
              customSize={18}
              className="configIcon"
            />
          </Button>
        </BtnContainer>
      </Container>

      <Container>
        <Title>Atividades</Title>
        <Main>
          {(!phasesData || phasesData?.length === 0) && (
            <Warning>
              Para configurar a validação das etapas nas atividades, é necessário ter cadastrada ao menos uma etapa.
            </Warning>
          )}

          <SwitchContainer>
            <SwitchStyled
              checked={AllowOnlyStagesLinkedToDisciplinesInTopics}
              onChange={() => setShowDisciplineActivitiesModal(true)}
            />
            <div className="textContent">
              <span className="title">
                Validar vinculação de disciplinas nas atividades
              </span>
              <span className="text">
                O campo de etapas só irá mostrar as etapas que estão vinculadas às disciplinas selecionadas no campo Disciplinas da atividade.
              </span>
            </div>
          </SwitchContainer>
        </Main>
      </Container>

      {showEdit && (
        <CreateOrEdit
          visible={showEdit}
          editStage={stageForEdit}
          disciplines={disciplines}
          loading={loading}
          onSubmit={handleSubmit}
          onCancel={closeAllModal}
        />
      )}
      {showDelete && stageForEdit && (
        <DeleteModal
          visible={showDelete}
          name={stageForEdit.Name}
          stageId={stageForEdit.TopicStageId}
          isDefault={stageForEdit.IsDefault}
          loading={loading}
          onConfirm={handleDelete}
          onCancel={closeAllModal}
        />
      )}
      {showLibrary && (
        <Library
          visible={showLibrary}
          csId={csId}
          obras={obras}
          libraries={libraries}
          loading={loading}
          listLoading={listLibrariesLoading}
          onSubmit={handleImportSubmit}
          onCancel={closeAllModal}
        />
      )}
      {showPhaseNomenclaturaModal && (
        <PhaseNomenclatura
          visible={showPhaseNomenclaturaModal}
          active={ValidateStagesInNomenclature}
          loading={loading}
          onConfirm={() => toogleSettingsConfig(SettingsConfigEnum.validateStagesInNomenclature)}
          onCancel={closeAllModal}
        />
      )}
      {showIntegrationDisciplinesModal && (
        <IntegrationDiscipline
          visible={showIntegrationDisciplinesModal}
          active={AllowOnlyStagesLinkedToDisciplinesInUpload}
          onConfirm={() => toogleSettingsConfig(SettingsConfigEnum.allowOnlyStagesLinkedToDisciplinesInUpload)}
          onCancel={closeAllModal}
        />
      )}
      {showDisciplineActivitiesModal && (
        <DisciplineActivities
          visible={showDisciplineActivitiesModal}
          active={AllowOnlyStagesLinkedToDisciplinesInTopics}
          onConfirm={() => toogleSettingsConfig(SettingsConfigEnum.allowOnlyStagesLinkedToDisciplinesInTopics)}
          onCancel={closeAllModal}
        />
      )}
    </>
  )
}

export default Stages;
