import { FC, useCallback, useEffect, useRef, useState } from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Form from "Components/UI/Form/Form";
import schema from "./disciplines.schema";
import Field from "Components/UI/Field";
import Button from "Components/UI/Button";
import { IDiscipline, IDisciplineData } from "Data/interfaces/Obra/IObraData";
import Item from "./components/Item";
import {
  IObraDisciplinesRequest,
  IObraDisciplinesUpdate,
} from "Data/interfaces/Obra/IObraDisciplines";
import toastHandler from 'Utils/toastHandler';
import { useDispatch } from "react-redux";
import { ObraActions } from "Store/Obra/Obra.actions";
import {
  BtnContainer,
  Container,
  Content,
  Description,
  Divider,
  FormContainer,
  Main,
  Title,
} from "./styles";

interface InputDisciplineProps {
  CustomDispName: string
}

interface IDisciplines {
  csId: number;
  disciplines?: IDiscipline[];
  disciplinesData?: IDisciplineData[];
  loading?: boolean;
}

const Disciplines: FC<IDisciplines> = ({
  csId,
  disciplines,
  disciplinesData,
  loading,
}) => {
  const dispatch = useDispatch();

  const editSectionRef = useRef<HTMLDivElement>(null);
  const [showEdit, setShowEdit] = useState(false);
  const [notCheckedCount, setCheckedCount] = useState(0);
  const [disciplinesList, setDisciplinesList] = useState<IDisciplineData[]>([]);
  const [sentRequest, setSentRequest] = useState(false);

  const onCheck = (index: number) => {
    setDisciplinesList(prev => {
      prev[index].IsChecked = !prev[index].IsChecked;
      return [...prev];
    });
  };

  const addDiscipline = ({ CustomDispName }: InputDisciplineProps) => {
    const trimmedCustomDispName = CustomDispName.trim();
    if (disciplinesList.some(d => (
      d?.CustomDispName?.trim() === trimmedCustomDispName || d?.Name?.trim() === trimmedCustomDispName)
    )) {
      toastHandler.handler({ description: 'Disciplina já existente', icon: 'error', title: '' });
      return;
    };

    const newDiscipline: IDisciplineData = {
      DisciplineId: 9,
      Name: 'Personalizado',
      CustomDispName,
      IsChecked: true,
      IsCustom: true,
      Icon: 'menuContexto',
    };
    setDisciplinesList(prev => [...prev, newDiscipline]);
  };

  const hasErrors = () => {
    const withoutDisciplines = disciplinesList.every((discipline) => !discipline.IsChecked);
    if (withoutDisciplines) {
      return ['Pelo menos uma disciplina deve ser selecionada'];
    }
  };

  const handleSubmit = () => {
    if (!showEdit) return;

    const errors = hasErrors();
    if (errors !== undefined) {
      errors.forEach((error) => {
        toastHandler.handler({ description: error, icon: 'error', title: '' });
      });
    } else {
      const Disciplines: IObraDisciplinesUpdate[] = disciplinesList
        .filter((discipline) => discipline.IsChecked)
        .map((discipline) => ({
          customName: discipline.CustomDispName,
          disciplineFk: discipline.DisciplineId,
          isCustom: discipline.IsCustom,
        }));

      const request: IObraDisciplinesRequest = {
        constructionStieId: csId,
        disciplines: Disciplines,
      };
      dispatch(ObraActions.updateDisciplines(request));
      setSentRequest(true);
    }
  }

  const handleStorageList = useCallback(() => {
    const list: IDisciplineData[] = [];
    let notChecked = 0;

    if (
      disciplines && disciplines.length > 0 &&
      disciplinesData && disciplinesData.length > 0
    ) {
      for (const discipline of disciplines) {
        const disciplineIndex = disciplinesData.findIndex((disciplineChecked) => {
          return !disciplineChecked.IsCustom &&
            disciplineChecked.DisciplineId === discipline.DisciplineFk;
        });

        if (disciplineIndex !== -1) {
          list.push({
            ...disciplinesData[disciplineIndex],
            IsChecked: true,
            Disabled: true
          });
        } else {
          list.push({
            Name: 'Personalizado',
            CustomDispName: discipline.CustomName,
            DisciplineId: 9,
            Icon: 'menuContexto',
            IsChecked: true,
            IsCustom: true,
            Disabled: true
          });
        }
        notChecked++;
      }

      for (const discipline of disciplinesData) {
        const disciplineIndex = list.findIndex((disciplineChecked) => {
          return !disciplineChecked.IsCustom &&
            disciplineChecked.DisciplineId === discipline.DisciplineId;
        });

        if (disciplineIndex === -1) {
          list.push({
            ...discipline,
            Disabled: false
          });
        }
      }
    }

    setDisciplinesList(list);
    setCheckedCount(notChecked);
  }, [disciplines, disciplinesData]);

  useEffect(() => {
    handleStorageList();
  }, [disciplines, disciplinesData, handleStorageList]);

  useEffect(() => {
    if (disciplinesList.filter(d => d.IsChecked).length > notCheckedCount) {
      setShowEdit(true);
      if (editSectionRef.current) {
        editSectionRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      setShowEdit(false);
    }
  }, [disciplinesList, notCheckedCount]);

  useEffect(() => {
    if (showEdit && editSectionRef.current) {
      editSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [showEdit, editSectionRef]);

  useEffect(() => {
    if (sentRequest && !loading) {
      setSentRequest(false);
      setShowEdit(false);
    }
  }, [sentRequest, loading]);

  return (
    <Container>
      <Title>Disciplinas</Title>
      <Main>
        <Description>Disciplinas da obra</Description>
        <Content>
          {disciplinesList.map((discipline, index) => (
            <Item
              key={`item-${index}`}
              name={discipline.CustomDispName ?? discipline.Name}
              checked={discipline.IsChecked}
              disabled={discipline.Disabled}
              loading={loading}
              onCheck={() => onCheck(index)}
            />
          ))}
        </Content>
      </Main>

      <FormContainer>
        <Description>
          Não achou a disciplina desejada? Configure uma nova disciplina
        </Description>
        <Form schema={schema} onSubmit={addDiscipline} resetForm={true}>
          <div className="formWrapper">
            <Field
              name='CustomDispName'
              label=''
              placeholder="Nova disciplina"
              className="fieldName"
            />
            <Button type="text" htmlType="submit" className="formBtn">
              Criar disciplina
            </Button>
          </div>
        </Form>
      </FormContainer>

      <div
        ref={editSectionRef}
        style={{ display: showEdit ? 'block' : 'none' }}
      >
        <Divider nottop />
        <BtnContainer>
          <Button
            type="primary"
            className="saveBtn"
            onClick={loading ? undefined : handleSubmit}
          >
            {loading && (
              <Spin
                indicator={
                  <LoadingOutlined
                    rev=""
                    color="white"
                    style={{ fontSize: 12, color: 'white', marginRight: 6 }}
                  />
                }
              />
            )}
            {loading ? 'Salvando...' : 'Salvar alterações'}
          </Button>
          <Button
            type="text"
            className="cancelBtn"
            onClick={handleStorageList}
          >
            Cancelar
          </Button>
        </BtnContainer>
      </div>
    </Container>
  )
}

export default Disciplines;
