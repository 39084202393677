import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Icon } from "Components/UI";
import {
  IDiscipline,
  IDisciplineDataStage,
} from "Data/interfaces/Obra/IObraData";
import Button from "Components/UI/Button";
import Modal from "Components/UI/Modal/Modal";
import { Checkbox, ConfigProvider, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Form from "Components/UI/Form/Form";
import schema from "./stages.schema";
import { InputStageProps, InputStageRequest } from "../../stages.model";
import Field from "Components/UI/Field";
import SearchInput from "Components/UI/SearchInput";
import { useSelector } from "react-redux";
import { getTheme } from "Store/MultiDomain/MultiDomain.selector";
import ItemDiscipline from "../ItemDiscipline";
import { IStage } from "Data/interfaces/Obra/IObraStages";
import Tooltip from "Components/UI/Tooltip";
import {
  BtnContainer,
  Container,
  Content,
  FormContainer,
  Main,
  SearchContainer,
  Title,
} from "./styles";

interface ICreateOrEdit {
  visible: boolean;
  editStage: IStage | null;
  loading?: boolean;
  disciplines?: IDiscipline[];
  onSubmit: (values: InputStageRequest) => void;
  onCancel: () => void;
}

const CreateOrEdit: FC<ICreateOrEdit> = ({
  visible,
  editStage,
  loading,
  disciplines,
  onSubmit,
  onCancel,
}) => {
  const theme = useSelector(getTheme);

  const [isSubmit, setIsSubmit] = useState(false);
  const [acronym, setAcronym] = useState('');
  const [stageName, setStageName] = useState('');
  const [checked, setChecked] = useState(editStage?.IsDefault || false);
  const [search, setSearch] = useState('');
  const [disciplinesList, setDisciplinesList] = useState<IDisciplineDataStage[]>([]);
  const disabled = !acronym || !stageName;

  const filteredDisciplines = useMemo(() => {
    if (search && disciplinesList?.length > 0) {
      return disciplinesList.filter(discipline => {
        return discipline.Name?.toLowerCase()?.includes(search?.toLowerCase());
      })
    }

    return disciplinesList;
  }, [search, disciplinesList]);

  const handleSend = () => {
    setIsSubmit(true);
  }

  const handleSubmit = (values: InputStageProps, errors?: string[]) => {
    if (!values || errors) return;

    const disciplineIds = disciplinesList
      .filter(discipline => discipline.IsChecked)
      .map(discipline => discipline.ConstructionSiteDisciplinesId);

    const currentValues: InputStageRequest = {
      ...values,
      isDefault: checked,
      disciplineIds
    };
    onSubmit(currentValues);

    setIsSubmit(false);
  };

  const onCheck = (index: number) => {
    setDisciplinesList(prev => {
      prev[index].IsChecked = !prev[index].IsChecked;
      return [...prev];
    });
  };

  const onCheckAll = (unmarkAll: boolean) => {
    setDisciplinesList(prev => {
      for (const i in prev) {
        if (unmarkAll) prev[i].IsChecked = false;
        else prev[i].IsChecked = true;
      }
      return [...prev];
    });
  };

  const handleStorageList = useCallback(() => {
    const list: IDisciplineDataStage[] = [];

    if (disciplines && disciplines?.length > 0) {
      for (const discipline of disciplines) {
        if (discipline.ConstructionSiteDisciplinesId) {
          const IsChecked = editStage
            ? editStage.ConstructionSiteStageDisciplines?.some(d => {
              return d.ConstructionSiteDisciplineFk === discipline.ConstructionSiteDisciplinesId;
            })
            : false;

          list.push({
            ConstructionSiteDisciplinesId: discipline.ConstructionSiteDisciplinesId,
            IsChecked,
            Name: discipline?.CustomName || discipline?.Discipline?.Name || '',
            Icon: discipline.Discipline?.Icon || '',
          });
        }
      }
    }

    setDisciplinesList(list);
  }, [disciplines, editStage]);

  useEffect(() => {
    handleStorageList();
  }, [disciplines, handleStorageList]);

  return (
    <Modal
      visible={visible}
      width={450}
      footerButtons={(
        <ConfigProvider theme={{
          token: {
            colorBgContainerDisabled: loading ? theme.colors.surface.surfaceOutline : `color-mix(in srgb, ${theme.colors.primary.primaryOutline}, ${theme.colors.surface.surface})`,
            colorTextDisabled: theme.colors.primary.onPrimary,
            colorPrimary: theme.colors.primary.primary
          }
        }}>
          <BtnContainer>
            <div className="btnContainerLeft">
              <Checkbox
                checked={checked}
                onChange={() => setChecked(prev => !prev)}
                disabled={loading}
              />
              <span className="leftText">
                Definir como padrão
              </span>
              <Tooltip
                overlay="Essa etapa aparecerá em todas as disciplinas."
                placement="top"
              >
                <span>
                  <Icon
                    icon="informacaoAdicional"
                    customSize={15}
                    className="iconInfo"
                  />
                </span>
              </Tooltip>
            </div>
            {disabled && (
              <Button type="text" className="actionBtn" disabled>
                Adicionar etapa
              </Button>
            )}
            {!disabled && (
              <Button type="primary" className="actionBtn" onClick={handleSend}>
                {loading && (
                  <Spin
                    indicator={
                      <LoadingOutlined
                        rev=""
                        color="white"
                        style={{ fontSize: 12, color: 'white', marginRight: 6 }}
                      />
                    }
                  />
                )}
                {loading ? 'Adicionando...' : 'Criar etapa'}
              </Button>
            )}
          </BtnContainer>
        </ConfigProvider>
      )}
    >
      <Container>
        <Title>
          <span className="textTitle">Criar nova etapa</span>
          <Icon
            icon="cancelar"
            customSize={12}
            className="iconTitle"
            onClick={onCancel}
          />
        </Title>

        <Content>
          <FormContainer>
            <Form
              schema={schema}
              onSubmit={handleSubmit}
              isSubmited={isSubmit}
              initialValues={{
                acronym: editStage?.Acronym || '',
                stageName: editStage?.Name || '',
              }}
            >
              <div className="formFieldsContainer">
                <span className="containerAcronym">
                  <span className="fieldLabel">
                    Sigla <span className="required">*</span>
                  </span>
                  <Field
                    name='acronym'
                    label=''
                    className="fieldAcronym"
                    value={acronym}
                    onCustomChange={setAcronym}
                  />
                </span>
                <span className="containerStageName">
                  <span className="fieldLabel">
                    Nome da Etapa <span className="required">*</span>
                  </span>
                  <Field
                    name='stageName'
                    label=''
                    className="fieldStageName"
                    value={stageName}
                    onCustomChange={setStageName}
                  />
                </span>
              </div>
            </Form>
          </FormContainer>

          <SearchContainer>
            <div className="titleContainer">
              <span className="titleSearch">Disciplinas vinculadas</span>
              <span
                className="titleBtn"
                onClick={() => disciplinesList.every(d => d.IsChecked)
                  ? onCheckAll(true)
                  : onCheckAll(false)
                }
              >
                {disciplinesList.every(d => d.IsChecked)
                  ? 'Desmarcar todas'
                  : 'Selecionar todas'
                }
              </span>
            </div>
            <div className="searchBox">
              <SearchInput
                setSearch={setSearch}
                inputPlaceholder="Pesquisar disciplina..."
                style={{
                  backgroundColor: theme.colors.secondary.secondary,
                }}
              />
            </div>
          </SearchContainer>

          <Main>
            {filteredDisciplines.map((discipline, index) => (
              <ItemDiscipline
                key={`item-${index}`}
                name={discipline.Name}
                checked={discipline.IsChecked}
                loading={loading}
                onCheck={() => onCheck(index)}
              />
            ))}
          </Main>
        </Content>
      </Container>
    </Modal>
  )
}

export default CreateOrEdit;
