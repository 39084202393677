import { FC, useEffect, useMemo, useState } from "react";
import { Icon, IconName } from "Components/UI";
import Button from "Components/UI/Button";
import {
  ConstructionSiteStageLibraryEnum,
  IConstructionSiteStageLibrary,
  IConstructionSiteStageLibraryItem,
} from "Data/interfaces/Obra/IObraLibraries";
import {
  Body,
  BodyContent,
  Children,
  Container,
  Content,
  Footer,
  HeaderContent,
  Hr,
  Left,
  Main,
  MainContent,
  Section,
  Top,
} from "./styles";

interface OptionsDefault {
  title: string;
  children: IConstructionSiteStageLibrary[];
}

interface ILibrary {
  loading?: boolean;
  listLoading?: boolean;
  libraries: IConstructionSiteStageLibrary[];
  onOpen: (lib: IConstructionSiteStageLibrary) => void;
  onDelete: (lib: IConstructionSiteStageLibrary) => void;
}

const iconNameTypeEnum: Record<ConstructionSiteStageLibraryEnum, IconName> = {
  [ConstructionSiteStageLibraryEnum.Standard]: 'coordly',
  [ConstructionSiteStageLibraryEnum.MarketStandard]: 'abnt',
};

const Library: FC<ILibrary> = ({
  loading,
  listLoading,
  libraries,
  onOpen,
  onDelete,
}) => {
  const [active, setActive] = useState(0);

  const options = useMemo(() => {
    const data: OptionsDefault[] = [];

    if (libraries && libraries.length > 0) {
      const standardLibraries = libraries.filter(lib => lib.Type === ConstructionSiteStageLibraryEnum.Standard);
      const marketLibraries = libraries.filter(lib => lib.Type === ConstructionSiteStageLibraryEnum.MarketStandard);

      if (standardLibraries.length > 0) {
        const opt: OptionsDefault = {
          title: 'Selecione um padrão de etapas',
          children: []
        };

        for (const lib of standardLibraries) {
          opt.children.push(lib);
        }

        data.push(opt);
      }

      if (marketLibraries.length > 0) {
        const opt: OptionsDefault = {
          title: 'Padrões do mercado',
          children: []
        };

        for (const lib of marketLibraries) {
          opt.children.push(lib);
        }

        data.push(opt);
      }
    }

    return data;
  }, [libraries]);

  const optionActive = useMemo(() => {
    return libraries.find(opt => {
      return opt.ConstructionSiteStageLibraryId === active;
    });
  }, [active, libraries]);

  const optionActiveChildren = useMemo(() => {
    let dataDefault: IConstructionSiteStageLibraryItem[] = [];

    if (optionActive && optionActive?.Items && optionActive.Items?.length > 0) {
      dataDefault = optionActive.Items.sort((a, b) => a.Order - b.Order);
    }

    const allOpts: number[] = [];
    for (const item of dataDefault) {
      allOpts.push(item.ConstructionSiteStageLibraryItemId);
    }

    return {
      data: dataDefault,
      dataChildIds: allOpts,
      count: allOpts.length,
    };
  }, [optionActive]);

  useEffect(() => {
    if (libraries && libraries.length > 0) {
      const standardLibrary = libraries.find(lib => {
        return lib.Type === ConstructionSiteStageLibraryEnum.Standard;
      });
      const currentActive = standardLibrary?.ConstructionSiteStageLibraryId ||
        libraries[0].ConstructionSiteStageLibraryId;

      setActive(currentActive);
    }
  }, [libraries]);

  return (
    <div>
      <Container>
        <Content>
          <Left>
            {options.map((opt, index) => (
              <Section key={opt.title}>
                <span className="title">{opt.title}</span>
                {opt.children.map(item => (
                  <Children
                    isactive={active === item.ConstructionSiteStageLibraryId}
                    onClick={() => setActive(item.ConstructionSiteStageLibraryId)}
                  >
                    {(item.Type === ConstructionSiteStageLibraryEnum.Standard ||
                      item.Type === ConstructionSiteStageLibraryEnum.MarketStandard) && (
                        <Icon
                          icon={iconNameTypeEnum[item.Type]}
                        />
                      )}
                    {/* {item.Type !== ConstructionSiteStageLibraryEnum.Standard &&
                      item.Type !== ConstructionSiteStageLibraryEnum.MarketStandard && (
                        <GetAvatar
                          name={item.title}
                          shape="circle"
                          size={20}
                          src={item.avatar}
                          thumbSrc={item.avatar}
                          thumbType="mid"
                        />
                      )} */}
                    <span className="titleChildren">{item.Name}</span>
                  </Children>
                ))}
                {index !== options.length - 1 && <Hr />}
              </Section>
            ))}
          </Left>

          <Main>
            {optionActive && (
              <Top>
                <span className="title">{optionActive.Name}</span>
                <span className="description">{optionActive?.Description}</span>
              </Top>
            )}
            <MainContent>
              <HeaderContent>
                <span className="columnSigla">Sigla</span>
                <span className="columnEtapa">Etapa</span>
              </HeaderContent>
              <Body>
                <BodyContent>
                  {optionActiveChildren.data.map(opt => (
                    <span key={opt.ConstructionSiteStageLibraryItemId} className="row">
                      <span className="rightRow">
                        <span className="columnSigla">{opt.Acronym}</span>
                        <span className="columnEtapa">{opt.Name}</span>
                      </span>
                    </span>
                  ))}
                </BodyContent>
              </Body>
              <Footer>
                <Button
                  type="primary"
                  className="btnFooter"
                  onClick={() => !!optionActive
                    ? onOpen(optionActive)
                    : undefined
                  }
                >
                  Editar
                </Button>
                <Button
                  type="primary"
                  danger
                  className="btnFooter btnFooterDanger"
                  onClick={() => !!optionActive
                    ? onDelete(optionActive)
                    : undefined
                  }
                >
                  Excluir
                </Button>
              </Footer>
            </MainContent>
          </Main>
        </Content>
      </Container>
    </div>
  )
}

export default Library;
